import styled from 'styled-components'

export const Container = styled.div`
	max-width: 1326px;
	width: 100%;
	min-height: 100%;
	padding: 0 15px;
	margin: 0 auto;
	
`
