import { SectionTitle } from "../../../components/SectionTitle";
import { S } from "./Recomendation_styles";

export const Recomendation: React.FC = () => {
  return (
    <S.Main>
      <S.TitleWrapper>
        <SectionTitle>Рекомендации и противопоказания</SectionTitle>
        <S.Text>
          <b>
            Противопоказания к массажу:
            <br />
            <br />
          </b>
          1.Беременность (1 триместр);
          <br /> 2.Наличие кардиостимулятора, металлических имплантов, протезов;{" "}
          <br />
          3.Эпилепсия, тяжелые соматические заболевания в анамнезе; <br />
          4.Онкология, злокачественные новообразования;
          <br />
          5.Варикозное расширение вен 2 и 3 степени, тромбофлебит; <br />
          6. Прием антикоагулянтов, нарушение свертываемости крови,
          гинекологические заболевания (со справкой от гинеколога); <br />
          7.Женщинам в период менструации( первые три дня); <br />
          8.Воспалительные элементы в зоне воздействия (угревая сыпь,
          фурункулы); <br />
          9.Открытые Раны, ссадины, повреждения, аллергическая сыпь.
          <br />
          <br />
          <b>
            Рекомендации перед процедурой:
            <br />
            <br />
          </b>
          -Не принимайте высококалорийную пищу за час до сеанса.
          <br />
          -Непосредственно перед сеансом выпейте не менее 200мл чистой,
          негазированной воды.
          <br />
          -Избегайте чрезмерных физических нагрузок (ведь массаж - это тоже
          большая нагрузка на организм!),
          <br />
          -Не употребляйте жирную, острую, копченую пищу, а также полностью
          исключите сладкую газированную воду и алкоголь.Рекомендации после
          сеанса массажа!
          <br />
          <br />
          <b>
            Рекомендации после процедуры:
            <br />
            <br />
          </b>
          Чтобы закрепить пользу и пролонгировать действия от массажа, мы
          рекомендуем нашим клиентам:
          <br />
          <br />
          1. Пить теплую воду, она поддержит водный баланс в организме и будет
          участвовать в ускорение обменного процесса.
          <br />
          2. Отдых. Идеально будет прилечь или заняться легкой деятельностью,
          чтобы дать телу время восстановиться.
          <br />
          3. За пару часов до сна примите теплый или терпимо горячий душ или
          ванну. Горячая вода поможет ускорить выведение молочной кислоты из
          мышц.
          <br />
          <br />
          На следующий день:
          <br />
          <br />
          1. Выполните, пожалуйста, легкие физические упражнения ЛФК. Это
          поможет ускорить процесс распада молочной кислоты, улучшить гибкость,
          ускорит кровообращение и подарит заряд и бодрость на весть день!
          <br />
          <br />
          ВАЖНО:
          <br />
          1. Не переохлаждаться после массажа
          <br />
          2. Не пить алкоголь после сеанса
          <br />
          3. Не курить первые 40 минут после массажа. Курение приводит к
          проблемам с сердечно-сосудистой системой.
          <br />
          <br />
          Следуя этим рекомендациям, вы сможете максимально эффективно
          воспользоваться преимуществами массажной терапии.
          <br />
          <br />
          <b>У Вас критические дни:</b>
          <br />
          <br />
          Менструация не является прямым противопоказанием, однако стоит
          учитывать, что некоторые женщины могут испытывать усиление
          кровотечения и болевые ощущения.
          <br />В любом случае, рекомендуется слушать свое тело.
          <br />
          Как вы чувствуете себя во время менструации?
          <br />
          Опирайтесь только на свои ощущения!
        </S.Text>
      </S.TitleWrapper>
    </S.Main>
  );
};
